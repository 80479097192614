import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { message } from '~helper/_common';

import { ActiveButtonName } from '~modules/activeButton';

import { Button } from '~components/atoms/Button';
import { ActiveButton } from '~components/organism/ActiveButton';

import style from './ConfirmationDialog.module.scss';

export type ConfirmationDialogProps = {
    children: React.ReactNode;
    headline: string;
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    activeButtonName?: ActiveButtonName;
    'data-test'?: string;
};

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    headline,
    open,
    children,
    onCancel,
    onConfirm,
    activeButtonName,
    'data-test': dataTest = 'confirmationDialog',
}) => {
    const ButtonComponent = activeButtonName ? ActiveButton : Button;

    return (
        <Dialog open={ open } data-test={ dataTest }>
            <DialogTitle>
                <div className={ style.root__headline } data-test={ `${dataTest}.headline` }>
                    { headline }
                </div>
            </DialogTitle>
            <DialogContent data-test={ `${dataTest}.content` }>
                { children }
            </DialogContent>
            <DialogActions>
                <Button
                    className={ style.root__button }
                    variant="outlined"
                    onClick={ onCancel }
                    data-test={ `${dataTest}.cancel` }
                >
                    { message('dialog.cancel') }
                </Button>
                <ButtonComponent
                    className={ style.root__button }
                    color="secondary"
                    onClick={ onConfirm }
                    data-test={ `${dataTest}.confirm` }
                    name={ activeButtonName as ActiveButtonName }
                >
                    { message('dialog.confirm') }
                </ButtonComponent>
            </DialogActions>
        </Dialog>
    );
};
