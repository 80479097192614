import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { IconButton, Paper, Popover } from '@mui/material';

import { useAnchor } from '~hooks/useAnchor';

import { ComponentCommonProps } from '~components/types';

import style from './FilterButton.module.scss';

export type FilterButtonRenderProps = {
    requestClose: () => void;
};

export type FilterButtonProps = ComponentCommonProps & {
    value?: string | null;
    render?: (props: FilterButtonRenderProps) => React.ReactNode;
    onClose?: () => void;
};

export const FilterButton: React.FC<FilterButtonProps> = ({
    value,
    render,
    onClose,
    'data-test': dataTest = 'filterButton',
}) => {
    const { anchor, setAnchor } = useAnchor<HTMLButtonElement>();
    const hasFilterValue = Boolean(value);

    const handleFilterButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget);
    };
    const handleRequestClose = () => {
        setAnchor(null);
    };
    const handlePopoverClose = () => {
        setAnchor(null);
        onClose?.();
    };

    return (
        <>
            <IconButton onClick={ handleFilterButtonClick } size="small" data-test={ dataTest }>
                <FilterAltIcon color={ hasFilterValue ? 'action' : 'disabled' } data-test={ `${dataTest}.icon` } />
            </IconButton>
            <Popover
                open={ Boolean(anchor) }
                anchorEl={ anchor }
                onClose={ handlePopoverClose }
                anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'left',
                } }
            >
                <Paper className={ style.filterPopover } data-test={ `${dataTest}.popover` }>
                    { render && render({ requestClose: handleRequestClose }) }
                </Paper>
            </Popover>
        </>
    );
};
