import React from 'react';
import clsx from 'clsx';

import { CreatorLevel as CreatorLevelEnum } from '~constants/creatorLevel';

import { message } from '~helper/_common';

import { LevelBadge } from '~components/atoms/LevelBadge';
import { Text } from '~components/atoms/Text';
import { ComponentCommonProps } from '~components/types';

import style from './CreatorLevel.module.scss';

export type CreatorLevelProps = ComponentCommonProps & {
    level: CreatorLevelEnum;
};

export const CreatorLevel: React.FC<CreatorLevelProps> = ({ level, className, 'data-test': dataTest = 'creatorLevel' }) => {
    return (
        <div className={ clsx(style.root, className) } data-test={ dataTest }>
            <LevelBadge level={ level } />
            <Text className={ style.root__label }>{ message(`creator.level.${level}`) }</Text>
        </div>
    );
};
