import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconButton } from '@mui/material';
import clsx from 'clsx';

import { ComponentCommonProps } from '~components/types';

import style from './SortButton.module.scss';

export type SortButtonDirection = 'ASC' | 'DESC' | null;

export type SortButtonProps = ComponentCommonProps & {
    direction: SortButtonDirection;
    onClick: (direction: SortButtonDirection) => void;
}

export const SortButton: React.FC<SortButtonProps> = ({
    direction,
    onClick,
    'data-test': dataTest = 'sortButton',
}) => {
    const handleClick = () => {
        if (direction === null) {
            onClick('DESC');
        } else if (direction === 'DESC') {
            onClick('ASC');
        } else {
            onClick(null);
        }
    };

    return (
        <IconButton
            size="small"
            onClick={ handleClick }
            data-test={ dataTest }
        >
            <ArrowDownwardIcon
                color={ direction !== null ? 'action' : 'disabled' }
                className={ clsx(style.icon, direction === 'ASC' ? style.iconReversed : undefined) }
                data-test={ `${dataTest}.icon` } />
        </IconButton>
    );
};
