import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TransgenderIcon from '@mui/icons-material/Transgender';
import { Tooltip } from '@mui/material';

import { Gender } from '~api/model/creator';

import { message } from '~helper/_common';

import { ComponentCommonProps } from '~components/types';

export type GenderIconProps = ComponentCommonProps & {
    gender: Gender;
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

export const GenderIcon: React.FC<GenderIconProps > = ({
    fontSize,
    gender,
    'data-test': dataTest = 'genderIcon'
}) => {
    return (
        <Tooltip title={ message(`creator.gender.${gender}`) } data-test={ `${dataTest}.description` }>
            <span data-test={ dataTest }>
                { gender === Gender.FEMALE && <FemaleIcon fontSize={ fontSize } data-test={ `${dataTest}.female` } /> }
                { gender === Gender.MALE && <MaleIcon fontSize={ fontSize } data-test={ `${dataTest}.male` } /> }
                { gender === Gender.DIVERSE && <TransgenderIcon fontSize={ fontSize } data-test={ `${dataTest}.diverse` } /> }
                { gender === Gender.UNKNOWN && <QuestionMarkIcon fontSize={ fontSize } data-test={ `${dataTest}.unknown` } /> }
            </span>
        </Tooltip>
    );
};
