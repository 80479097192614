import { useState } from 'react';

export type DefaultValueFormat = string;

export interface UseFormInputValueReturn<ValueFormat = string | undefined> {
    value: ValueFormat;
    setValue: (value: ValueFormat) => void;
}

export const useFormInputValue = <ValueFormat = DefaultValueFormat>(initialValue: ValueFormat | undefined = undefined): UseFormInputValueReturn<ValueFormat | undefined> => {
    const [ value, setValue ] = useState<ValueFormat | undefined>(initialValue);

    return {
        value,
        setValue,
    };
};
