import React from 'react';
import clsx from 'clsx';

import { CreatorLevel } from '~constants/creatorLevel';

import { ComponentCommonProps } from '~components/types';

import style from './LevelBadge.module.scss';

export type LevelBadgeProps = ComponentCommonProps & {
    className?: string;
    level: CreatorLevel;
    size?: 'medium' | 'large';
};

export const LevelBadge: React.FC<LevelBadgeProps> = ({
    className,
    level,
    size,
    'data-test': dataTest = 'levelBadge',
}) => {
    return (
        <div
            className={ clsx(style.root, style[`root__${level}`], className, {
                [style.large]: size === 'large',
            }) }
            data-test={ dataTest }
        />
    );
};
