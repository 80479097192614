import { useState } from 'react';

export type UseHtmlButtonAnchorReturn<AnchorType> = {
    anchor: AnchorType | null;
    setAnchor: (anchor: AnchorType | null) => void;
}

export const useAnchor = <AnchorType>(): UseHtmlButtonAnchorReturn<AnchorType> => {
    const [ anchor, setAnchor ] = useState<AnchorType | null>(null);

    return {
        anchor,
        setAnchor,
    };
};
