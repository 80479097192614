import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { Mui } from '~constants/mui';

import { message } from '~helper/_common';

import { useOnValueSet } from '~hooks/useOnValueSet';

import { resetMessages } from '~modules/messages';
import {
    confirmPayoutStatusChange,
    PayoutStatusChangeConfirmDialogState,
    resetPayoutStatusChange,
    usePayoutStatusChangeConfirmDialog,
} from '~modules/payoutStatusChangeConfirmDialog';

import { Messages } from '~components/molecules/Messages';
import { ConfirmationDialog } from '~components/organism/ConfirmationDialog';
import { ComponentCommonProps } from '~components/types';

const getHeadline = (state: PayoutStatusChangeConfirmDialogState): string => {
    const nickname = state.payout?.nickname || message('payout.statusChange.noName');

    switch (state.payoutStatus) {
        case 'stop':
            return message('payout.statusChange.toStop.headline', nickname);
        case 'onHold':
            return message('payout.statusChange.toOnHold.headline', nickname);
        case 'resume':
            return message('payout.statusChange.toResume.headline', nickname);
    }

    return '';
};

const getDescription = (state: PayoutStatusChangeConfirmDialogState): string => {
    switch (state.payoutStatus) {
        case 'stop':
            return message('payout.statusChange.toStop.description');
        case 'onHold':
            return message('payout.statusChange.toOnHold.description');
        case 'resume':
            return message('payout.statusChange.toResume.description');
    }

    return '';
};

export const PayoutStatusChangeConfirmDialog: React.FC<ComponentCommonProps> = ({
    'data-test': dataTest = 'payoutStatusChangeConfirmDialog',
}) => {
    const dispatcher = useDispatch();
    const payoutStatusChangeDialogState = usePayoutStatusChangeConfirmDialog();
    const payout = payoutStatusChangeDialogState.payout;

    useOnValueSet(() => {
        dispatcher(resetMessages('payoutStatusChangeConfirmDialog'));
    }, payout);

    const handleConfirm = () => {
        if (payout) {
            dispatcher(confirmPayoutStatusChange());
        }
    };

    const handleCloseDialog = () => {
        dispatcher(resetPayoutStatusChange());
    };

    return (
        <ConfirmationDialog
            open={ Boolean(payout) }
            headline={ getHeadline(payoutStatusChangeDialogState) }
            onCancel={ handleCloseDialog }
            onConfirm={ handleConfirm }
            activeButtonName="setPayoutOnHoldOrResume"
            data-test={ dataTest }>
            <Grid container spacing={ Mui.gridSpacing }>
                <Grid item xs={ 12 }>
                    <Messages
                        position="payoutStatusChangeConfirmDialog"
                        data-test={ `${dataTest}.messages` }
                    />
                </Grid>
                <Grid item xs={ 12 }>
                    <Typography data-test={ `${dataTest}.description` }>
                        { getDescription(payoutStatusChangeDialogState) }
                    </Typography>
                </Grid>
            </Grid>
        </ConfirmationDialog>
    );
};
