import { CreatorPayoutCycleRun, PayoutCycleRunCreator, PayoutMethod } from '~api/model/payout-cycle';

import { CreatorLevel } from '~constants/creatorLevel';

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    DIVERSE = 'DIVERSE',
    UNKNOWN = 'UNKNOWN',
}

export type CreatorCommons = {
    levelId: CreatorLevel;
    creatorId: string;
    nickname: string | null;
    imageUrl: string | null;
    blocked: boolean;
    fraud: boolean;
    nextPayoutDate: string | null;
    creationDate: string;
    isCancellationDue: boolean;
}

export type CreatorCoreInformation = CreatorCommons & {
    email: string | null;
    age: number | null;
    gender: Gender;
    postalCode: string | null;
    city: string | null;
    withCompensation: boolean | null;
    payoutMethod: PayoutMethod | null;
    cdcId: string;
}

export type Creator = CreatorCommons & {
    billableLiveViewCount: number;
    billableReplayViewCount: number;
    showCount: number;
    clipCount: number;
    beautyPointsCollected: number;
    beautyPointsCollectedSinceLastPayout: number;
    beautyPointsPaidOut: number;
    cashCollected: number;
    cashCollectedSinceLastPayout: number;
    cashPaidOut: number;
    compensableLiveViewCount: number;
    compensableReplayViewCount: number;
    age: number | null;
    city: string | null;
    gender: Gender;
    cdcId: string;
    payoutMethod: PayoutMethod;
};

export type Video = {
    name: string;
    creationTime: string;
    replayViews: number;
    liveViews: number | null;
    compensableLiveViewCount: number | null;
    compensableReplayViewCount: number;
}

export type CreatorVideosOverview = {
    totalShows: number;
    totalClips: number;
    showsOverview: Array<Video>;
    clipsOverview: Array<Video>;
}

export type CreatorLevelChangeDetails = {
    previousLevel: CreatorLevel;
    currentLevel: CreatorLevel;
    date: string;
}

export type CreatorsLevelChangeOverview = {
    name: string;
    creatorLevelChangeDetails: Array<CreatorLevelChangeDetails>;
}

export const FilterableCreatorVideosTableFieldNames = [
    'name'
] as const;

export type FilterableCreatorVideosTableFieldNamesType = typeof FilterableCreatorVideosTableFieldNames[number];

export type FilterableCreatorVideosTableFields = Pick<Video, FilterableCreatorVideosTableFieldNamesType>;

export const SortableCreatorVideosTableFieldNames = [
    'name',
    'creationTime',
    'liveViews',
    'replayViews',
    'compensableLiveViewCount',
    'compensableReplayViewCount',
] as const;

export type SortableCreatorVideosTableFieldNamesType = typeof SortableCreatorVideosTableFieldNames[number];

export type SortableCreatorVideosTableFields = Pick<Video, SortableCreatorVideosTableFieldNamesType>;

export type CreatorTableData = Partial<Creator & PayoutCycleRunCreator>;

export type CreatorVideosFilter = Record<keyof FilterableCreatorVideosTableFields, string | null> | null;

export type CreatorVideosPerPage = 10 | 25 | 50 | 100;

export type CreatorVideosSorting = {
    by: keyof SortableCreatorVideosTableFields;
    direction: 'ASC' | 'DESC';
} | null;

export type CreatorVideosPaginationOptions = {
    filter: Partial<CreatorVideosFilter>;
    sort: CreatorVideosSorting;
    resultsPerPage: CreatorVideosPerPage;
    page: number;
};

export const FilterableCreatorTableFieldNames = [
    'nickname',
    'creatorId',
    'levelId',
    'city',
    'age',
    'gender',
    'payoutMethod',
    'cdcId',
] as const;

export type FilterableCreatorTableFieldNamesType = typeof FilterableCreatorTableFieldNames[number];

export type FilterableCreatorTableFields = Pick<CreatorTableData, FilterableCreatorTableFieldNamesType>;

export const SortableCreatorTableFieldNames = [
    'creatorId',
    'nickname',
    'levelId',
    'liveViewCount',
    'replayViewCount',
    'showCount',
    'clipCount',
    'beautyPointsCollected',
    'beautyPointsCollectedSinceLastPayout',
    'beautyPointsPaidOut',
    'cashPaidOut',
    'cashCollected',
    'cashCollectedSinceLastPayout',
    'payoutMethod',
    'nextPayoutDate',
    'creationDate',
    'billableLiveViewCount',
    'billableReplayViewCount',
    'compensableLiveViewCount',
    'compensableReplayViewCount',
    'city',
    'age',
    'billingPeriodStart',
    'billingPeriodEnd',
    'cdcId',
] as const;

export type SortableCreatorTableFieldNamesType = typeof SortableCreatorTableFieldNames[number];

export type SortableCreatorTableFields = Pick<CreatorTableData, SortableCreatorTableFieldNamesType>;

export type CreatorFilter = Record<keyof FilterableCreatorTableFields, string | null> | null;

export type CreatorsPerPage = 10 | 25 | 50 | 100;

export type CreatorSorting = {
    by: keyof SortableCreatorTableFields;
    direction: 'ASC' | 'DESC';
} | null;

export type CreatorFilterOptions = {
    filter: Partial<CreatorFilter>;
    sort: CreatorSorting;
    resultsPerPage: CreatorsPerPage;
    page: number;
};

export type CreatorPayoutOverview = {
    upcomingPayout: CreatorPayoutCycleRun | null;
    completed: Array<CreatorPayoutCycleRun>;
}

export type BehaviourBeautyPointsReason = 'NUMBER_OF_IMPRESSIONS_TRIGGER_1K'
|'NUMBER_OF_IMPRESSIONS_TRIGGER_10K'
|'NUMBER_OF_IMPRESSIONS_TRIGGER_25K'
|'NUMBER_OF_IMPRESSIONS_TRIGGER_50K'
|'NUMBER_OF_SHOWS_TRIGGER_100'

export type CreatorBehaviourBeautyPoints = {
    reason: BehaviourBeautyPointsReason;
    date: string;
    points: number;
};

export enum CreatorChangeType {
    WITH_COMPENSATION = 'WITH_COMPENSATION',
    LEVEL_ID = 'LEVEL_ID',
    PAYOUT_METHOD = 'PAYOUT_METHOD',
}

export interface CreatorChange<ChangeType extends CreatorChangeType, Value extends string> {
    type: ChangeType;
    date: string;
    previousValue: Value;
    currentValue: Value;
    trigger: 'SYSTEM' | 'MANUAL' | 'COPS' | string;
}

export type LevelIdCreatorChange = CreatorChange<CreatorChangeType.LEVEL_ID, CreatorLevel>;
export type WithCompensationCreatorChange = CreatorChange<CreatorChangeType.WITH_COMPENSATION, 'true' | 'false'>;
export type PayoutMethodCreatorChange = CreatorChange<CreatorChangeType.PAYOUT_METHOD, PayoutMethod>;

export type CreatorChanges = Array<LevelIdCreatorChange | WithCompensationCreatorChange | PayoutMethodCreatorChange>;
