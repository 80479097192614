import React from 'react';
import { Alert } from '@mui/material';

import { Message, MessagePosition, MessageSeverity, useMessagesFor } from '~modules/messages';

import style from './Messages.module.scss';

export interface MessagesProps {
    position: MessagePosition;
    severity?: MessageSeverity;
    'data-test'?: string;
}

export const Messages: React.FC<MessagesProps> = ({
    position,
    severity,
    'data-test': dataTest = 'message',
}) => {
    const messages = useMessagesFor(position, severity);

    return <>
        {
            messages.map((message: Message, index: number): React.ReactElement =>
                <Alert
                    key={ index }
                    severity={ message.severity }
                    className={ style.root__message }
                    data-test={ dataTest }
                >
                    { message.text }
                </Alert>
            )
        }
    </>;
};
