import React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';
import clsx from 'clsx';

import { ActiveButtonName, setActiveButton, useActiveButton } from '~modules/activeButton';

import { Button } from '~components/atoms/Button';

import style from './ActiveButton.module.scss';

export interface ActiveButtonProps extends MuiButtonProps {
    name: ActiveButtonName;
    children: NonNullable<React.ReactNode>;
    setActiveButtonOnClick?: boolean;
    onClick?: () => void;
    'data-test'?: string;
}

export const ActiveButton: React.FC<ActiveButtonProps> = ({
    children,
    className,
    color = 'primary',
    name,
    setActiveButtonOnClick = true,
    onClick,
    'data-test': dataTest = 'activeButton',
    ...otherProps
}) => {
    const activeButton = useActiveButton();
    const dispatch = useDispatch();
    const isDisabled = !!activeButton.name;
    const isFetching = activeButton.name === name;
    const onClickHandler = () => {
        if (isDisabled) {
            return;
        }

        onClick?.();
        if (setActiveButtonOnClick) {
            dispatch(setActiveButton(name));
        }
    };

    return (
        <div className={ clsx(style.root, className) } data-test={ dataTest }>
            <Button
                { ...otherProps }
                color={ color }
                aria-label={ name }
                disabled={ isDisabled }
                data-test={ `${dataTest}.button` }
                onClick={ onClickHandler }
            >
                { children }
            </Button>
            { isFetching && (
                <CircularProgress
                    size={ 24 }
                    className={ style.root__progress }
                    data-test={ `${dataTest}.progress` } />
            ) }
        </div>
    );
};
