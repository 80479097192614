import BugReportIcon from '@mui/icons-material/BugReport';
import CancelIcon from '@mui/icons-material/Cancel';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EuroIcon from '@mui/icons-material/Euro';
import TollIcon from '@mui/icons-material/Toll';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import clsx from 'clsx';

import { message } from '~helper/_common';

import { Text } from '~components/atoms/Text';

import style from './Chip.module.scss';

export type ChipProps = {
    color?: 'default' | 'highlight' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | 'black';
    icon?: React.ReactNode;
    label: string;
    'data-test'?: string;
}

export const Chip: React.FC<ChipProps> = ({
    color = 'default',
    icon,
    label,
    'data-test': dataTest = 'chip'
}) => {
    return <div className={ clsx(style.root, style[color]) } data-test={ dataTest }>
        <Text variant="overline" className={ style.label } data-test={ `${dataTest}.label` }>{ label }</Text>
        { icon &&
            (<div className={ style.icon } data-test={ `${dataTest}.icon` }>
                { icon }
            </div>)
        }
    </div>;
};

export const ActiveChip: React.FC = () => <Chip color="success" label={ message('chip.active') } data-test="chip.active" />;
export const InactiveChip: React.FC = () => <Chip color="error" label={ message('chip.inactive') } data-test="chip.inactive" />;
export const UpcomingChip: React.FC = () => <Chip color="info" label={ message('chip.upcoming') } data-test="chip.upcoming" />;

export const DefaultPayoutCycleChip: React.FC = () => <Chip color="primary" label={ message('chip.payoutCycle.default') } data-test="chip.defaultPayoutCycle" />;
export const OnHoldChip: React.FC = () => <Chip color="error" label={ message('chip.onHold') } icon={ <DoDisturbIcon /> } data-test="chip.onHold" />;
export const StoppedChip: React.FC = () => <Chip color="error" label={ message('chip.stopped') } icon={ <DoDisturbIcon /> } data-test="chip.stopped" />;
export const CanceledChip: React.FC = () => <Chip color="error" label={ message('chip.canceled') } icon={ <DoDisturbIcon /> } data-test="chip.canceled" />;
export const PaidOutChip: React.FC = () => <Chip color="success" label={ message('chip.paidOut') } data-test="chip.paidOut" />;
export const CancellationDueChip: React.FC = () => <Chip color="error" label={ message('chip.cancellationDue') } icon={ <CancelIcon /> } data-test="chip.cancellationDue" />;

export const MoneyChip: React.FC = () => <Chip color="secondary" label={ message('chip.cash') } icon={ <EuroIcon /> } data-test="chip.cash" />;
export const BeautypointsChip: React.FC = () => <Chip color="primary" label={ message('chip.beautypoints') } icon={ <TollIcon /> } data-test="chip.beautypoints" />;

export const CompensatedThroughMoneyChip: React.FC = () => <Chip color="secondary" label={ message('chip.cash.compensatedThrough') } icon={ <EuroIcon /> } data-test="chip.cashCompensation" />;
export const CompensatedThroughBeautypointsChip: React.FC = () => <Chip color="primary" label={ message('chip.beautypoints.compensatedThrough') } icon={ <TollIcon /> } data-test="chip.beautypointsCompensation" />;

export const CreatorWithCompensationChip: React.FC = () => <Chip color="success" label={ message('chip.creatorWithCompensation') } data-test="chip.creatorWithCompensation" />;
export const CreatorWithoutCompensationChip: React.FC = () => <Chip color="error" label={ message('chip.creatorWithoutCompensation') } data-test="chip.creatorWithoutCompensation" />;

export const FraudChip: React.FC = () => <Chip color="black" label={ message('chip.fraud') } icon={ <BugReportIcon /> } data-test="chip.fraud" />;
export const FailedToLoadChip: React.FC = () => <Chip color="error" label={ message('chip.failedToLoad') } icon={ <WarningAmberIcon /> } data-test="chip.failedToLoad" />;

export const CreatedChip: React.FC = () => <Chip color="info" label={ message('chip.created') } data-test="chip.created" />;
export const ProcessedChip: React.FC = () => <Chip color="info" label={ message('chip.processed') } data-test="chip.processed" />;
export const DoneChip: React.FC = () => <Chip color="success" label={ message('chip.done') } data-test="chip.done" />;
