import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Divider, IconButton, InputBase } from '@mui/material';
import { partial } from 'lodash';

import { message } from '~helper/_common';

import { useFormInputValue } from '~hooks/useFormInputValue';

import { FilterButton } from '~components/molecules/FilterButton';
import style from '~components/molecules/FilterButton/FilterButton.module.scss';
import { ComponentCommonProps } from '~components/types';

export type TextFilterProps = ComponentCommonProps & {
    value?: string | null;
    onChange: (value: string | null) => void;
};

export const TextFilter: React.FC<TextFilterProps> = ({
    value,
    onChange,
    'data-test': dataTest = 'textFilter',
}) => {
    const { value: filterValue, setValue: setFilterValue } = useFormInputValue(value || '');
    const handleSubmitButtonClick = (requestClose: () => void) => {
        onChange(filterValue || null);
        requestClose();
    };
    const handleCancelButtonClick = (requestClose: () => void) => {
        onChange(null);
        setFilterValue('');
        requestClose();
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValue(event.target.value);
    };
    const handleKeyDown = (requestClose: () => void, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onChange(filterValue || null);
            requestClose();
        }
        if (event.key === 'Esc' || event.key === 'Escape') {
            event.preventDefault();
            onChange(null);
            setFilterValue('');
            requestClose();
        }
    };
    const handleClose = () => {
        setFilterValue(value || '');
    };

    return (
        <FilterButton
            value={ value }
            onClose={ handleClose }
            data-test={ dataTest }
            render={ ({ requestClose }) => (
                <>
                    <InputBase
                        placeholder={ message('creator.filterBy') }
                        size="small"
                        className={ style.textField }
                        value={ filterValue }
                        onChange={ handleInputChange }
                        onKeyDown={ partial(handleKeyDown, requestClose) }
                        autoFocus
                        inputProps={ {
                            'data-test': `${dataTest}.filterValue`
                        } }
                    />
                    <IconButton
                        size="small"
                        onClick={ partial(handleCancelButtonClick, requestClose) }
                        data-test={ `${dataTest}.cancelFilterButton` }
                    >
                        <ClearIcon color="secondary" />
                    </IconButton>
                    <Divider sx={ { height: 28, m: 0.5 } } orientation="vertical" />
                    <IconButton
                        size="small"
                        onClick={ partial(handleSubmitButtonClick, requestClose) }
                        data-test={ `${dataTest}.applyFilterButton` }
                    >
                        <CheckIcon color="primary" />
                    </IconButton>
                </>
            ) }
        />
    );
};
