import {
    FilterableCreatorTableFieldNames,
    FilterableCreatorTableFieldNamesType,
    FilterableCreatorVideosTableFieldNames,
    FilterableCreatorVideosTableFieldNamesType,
    SortableCreatorTableFieldNames,
    SortableCreatorTableFieldNamesType,
    SortableCreatorVideosTableFieldNames,
    SortableCreatorVideosTableFieldNamesType
} from '~api/model/creator';

export const isFilterableCreatorTableFieldName = (fieldName: string): fieldName is FilterableCreatorTableFieldNamesType => {
    return FilterableCreatorTableFieldNames.includes(fieldName as FilterableCreatorTableFieldNamesType);
};

export const isSortableCreatorTableFieldName = (fieldName: string): fieldName is SortableCreatorTableFieldNamesType => {
    return SortableCreatorTableFieldNames.includes(fieldName as SortableCreatorTableFieldNamesType);
};

export const isFilterableCreatorContentTableFieldName = (fieldName: string): fieldName is FilterableCreatorVideosTableFieldNamesType => {
    return FilterableCreatorVideosTableFieldNames.includes(fieldName as FilterableCreatorVideosTableFieldNamesType);
};

export const isSortableCreatorContentTableFieldName = (fieldName: string): fieldName is SortableCreatorVideosTableFieldNamesType => {
    return SortableCreatorVideosTableFieldNames.includes(fieldName as SortableCreatorVideosTableFieldNamesType);
};
