import { PayoutMethod } from '~api/model/payout-cycle';

export enum CreatorLevel {
    MEMBER = 'MEMBER',
    SILVER = 'SILVER',
    GOLD = 'GOLD',
    PLATINUM = 'PLATINUM',
}

export const creatorLevelOrder = [
    CreatorLevel.PLATINUM,
    CreatorLevel.GOLD,
    CreatorLevel.SILVER,
    CreatorLevel.MEMBER,
];

export const payoutMethodPerCreatorLevel = new Map<CreatorLevel, PayoutMethod[]>([
    [ CreatorLevel.PLATINUM, [ PayoutMethod.BEAUTY_POINTS, PayoutMethod.CASH ] ],
    [ CreatorLevel.GOLD, [ PayoutMethod.BEAUTY_POINTS, PayoutMethod.CASH ] ],
    [ CreatorLevel.SILVER, [ PayoutMethod.BEAUTY_POINTS, PayoutMethod.CASH ] ],
    [ CreatorLevel.MEMBER, [ PayoutMethod.BEAUTY_POINTS ] ]
]);
